<template>
  <div class="list-con" :style="{paddingTop: isWeixin_status ? '0px':'50px'}">
    <NavBar
      :title="type == 1 ? '兑换记录' : '历史'"
      fixed
      v-if="!isWeixin_status"
      @click-left="goBack"
      :left-arrow="isWeixin_status == false"
    ></NavBar>
    <PullRefresh
      v-if="type"
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="刷新成功"
    >
      <div class="totalizer-list">
        <div
          :class="`totalizer-item animation-${idx + 1}`"
          v-for="(item, idx) in listArr"
          :key="idx"
          @click="goDetail(item)"
        >
          <div class="totalizer-item-main">
            <div class="totalizer-item-img" v-if="item.goods">
              <img v-lazy="item.goods.logo" alt="" />
            </div>
            <div class="totalizer-item-left" v-if="item.goods">
              <span>{{ item.goods.title }} x{{ item.num }}</span>
              <span>{{ item.create_time }}</span>
            </div>
            <div class="integral">-{{ item.total_credit }}</div>
          </div>
          <div class="totalizer-item-btn">
            <!-- <div @click.stop="checkOnline(item)" style="margin-right:10px" v-if="item.status == 0">线上发货</div>
            <div style="margin-right:10px" v-else-if="item.status == 2">未发货</div>
            <div style="margin-right:10px" v-else-if="item.status == 3">已发货</div> -->
            <div
              @click.stop="checkStatus(item)"
              :style="item.status == 0 ? '' : 'background: #696969'"
              v-if="item.status == 0 || item.status == 1"
            >
              {{ item.status == 0 ? "未核销" : "已核销" }}
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <PullRefresh
      v-else
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="刷新成功"
    >
      <CellGroup>
        <div
          v-for="(item, idx) in listArr"
          :key="idx"
          :class="`totalizer-check animation-${idx + 1}`"
        >
          <div class="totalizer-check-left">
            <span>签到奖励</span>
            <span>{{ item.create_time }}</span>
          </div>
          <div class="check">+ {{ item.credit }}</div>
        </div>
      </CellGroup>
    </PullRefresh>
  </div>
</template>
<script>
import { PullRefresh, CellGroup, NavBar } from "vant";
import UserInfo from "@/api/user";
export default {
  components: {
    PullRefresh,
    CellGroup,
    NavBar,
  },
  data() {
    return {
      isLoading: false,
      count: 0,
      type: "",
      showCode: false,
      listArr: [],
      page: 0,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.getExChange();
    } else {
      this.getUserSignList();
    }
  },
  methods: {
    // 线上发货
    checkOnline(item) {
      console.log(item);
      this.$router.push({
        path: "/conversionOrder",
        query: {
          id: item.id,
          num: item.num,
          type: 1,
          total_credit: item.total_credit,
          order_sn: item.order_sn,
          name: item.goods.title,
          pic: item.goods.logo,
        },
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        if (this.$route.query.type) {
          this.type = this.$route.query.type;
          this.getExChange(++this.page);
        } else {
          this.getUserSignList();
        }
      }, 500);
    },
    goDetail(item) {
      this.$router.push({
        path: "/exchange_detail",
        query: {
          id: item.id,
          type: 0,
        },
      });
    },
    async getExChange(page) {
      try {
        const res = await UserInfo.getPointOrder(page, 1);
        if (res.code == 200) {
          if (res.data.total_num == this.listArr.length) {
            throw "暂无数据";
          } else {
            this.listArr = [...this.listArr, ...res.data.result];
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    async getUserSignList() {
      try {
        const res = await UserInfo.Sign_Records();
        if (res.code == 200) {
          if (res.data.result.length == 0) {
            throw "暂无数据";
          } else {
            this.listArr = res.data.result;
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    checkStatus(item) {
      try {
        if (item.status == 1) throw "已核销过该订单...";
        this.$router.push("/chargeoff?id=" + item.id);
      } catch (error) {
        this.$toast(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-con {
  width: 100%; 
  height: 100vh;
}
.sign-top {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  align-items: center;
  background: #ff5954;
  justify-content: space-between;
}
.totalizer-list {
  width: 96%;
  height: auto;
  margin: 10px auto;
  .totalizer-item {
    width: 100%;
    height: auto;
    display: flex;
    padding: 8px 10px;
    font-size: 14px;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    justify-content: space-between;
    .totalizer-item-main {
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: space-between;
      .totalizer-item-img {
        width: 60px;
        height: 60px;
        img {
          max-width: 100%;
          height: 100%;
        }
      }
      .totalizer-item-left {
        width: 60%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span:nth-child(1) {
          color: #000000;
        }
        span:nth-child(2) {
          color: #696969;
        }
      }
      .integral {
        color: #ff5954;
        font-size: 18px;
      }
    }
    .totalizer-item-btn {
      width: 100%;
      height: 30px;
      padding-top: 6px;
      display: flex;
      justify-content: flex-end;
      div {
        width: 80px;
        height: 26px;
        background: #ff5954;
        color: #ffffff;
        text-align: center;
        line-height: 26px;
        border-radius: 24px;
      }
    }
  }
}
.totalizer-check {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 10px;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  justify-content: space-between;
  .totalizer-check-left {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span:nth-child(1) {
      color: #000000;
    }
    span:nth-child(2) {
      color: #696969;
    }
  }
  .check {
    font-size: 16px;
    color: #ff5954;
  }
}
</style>